/**
 * Module definition and dependencies
 */
angular.module("Crm.RechercherContacts.Controller", ["contactsService.Service", "ngMessages"])
/**
 * Controller
 */
.controller("RechercherContactsCtrl", ["$state", "$scope", "ContactFicheConsultees", "Contacts", "$filter", function ($state, $scope, ContactFicheConsultees, Contacts, $filter) {
  var self = this;
  $scope.isOpen = false;

  self.$onInit = function () {
    if (!Array.isArray(self.listContacts)) {
      self.listContacts = [];
    }

    if (self.contactId) {
      self.getContact(self.contactId);
    }
  };

  self.getContacts = function (search) {
    var tabReturn = [];

    if (search === undefined || search.length === 0) {
      return ContactFicheConsultees.getContactFicheConsultees({
        ignoreVendeursInterceptor: true
      }).then(function (response) {
        tabReturn = response.data.data;
        angular.forEach(tabReturn, function (value, key) {
          value.label = value.nom + " " + value.prenom;
        });
        return tabReturn;
      }).catch(function (err) {
        console.log("promise error", err);
      });
    } else {
      return Contacts.query({
        ignoreVendeursInterceptor: true,
        q: search,
        limit: "-1"
      }).$promise.then(function (response) {
        tabReturn = response.data;
        angular.forEach(tabReturn, function (value, key) {
          value.label = value.nom + " " + value.prenom;
        });
        return tabReturn;
      });
    }
  };

  self.getContact = function (item) {
    var contactId;

    if (typeof item === "number") {
      contactId = item;
    } else {
      contactId = item.id;
    }

    Contacts.get({
      contactId: contactId
    }).$promise.then(function (data) {
      self.contact = data;

      if (!self.contact.label && self.showContactLabel !== false) {
        self.contact.label = self.contact.nom + " " + self.contact.prenom;
        $scope.contact = self.contact.label;
      }

      if (self.showContactLabel === false) {
        $scope.contact = "";
      }

      if (self.contact.id && !$filter("filter")(self.listContacts, {
        id: self.contact.id
      }, true)[0]) {
        self.listContacts.push(self.contact);
      }
    }).catch(function (err) {
      console.log("promise error", err);
    });
  };

  $scope.$watch(() => self.contact, function () {
    if (self.contact && self.redirect) {
      $state.go("formContact.historique", {
        contactId: self.contact.id
      });
    }

    document.getElementById("typeahead-input").blur();

    if (angular.equals(self.contact, {})) {
      delete $scope.contact;
    }
  }, true);
}]);
/**
 * Module definition and dependencies
 */

angular.module("Crm.RechercherContacts", ["Crm.RechercherContacts.Controller", "Crm.rechercher-contacts.templates"])
/**
 * Route component
 */
.component("rechercherContacts", {
  controller: "RechercherContactsCtrl",
  templateUrl: "src/rechercher-contacts/rechercher-contacts.html",
  bindings: {
    contact: "=",
    contactId: "<",
    showContactLabel: "<",
    listContacts: "=?",
    redirect: "<"
  }
});
angular.module('Crm.rechercher-contacts.templates', []).run(['$templateCache', function($templateCache) {$templateCache.put('src/rechercher-contacts/rechercher-contacts.html','<div class="typeahead-container"> <input id="typeahead-input" type="text" ng-model="contact" placeholder="Rechercher un contact" uib-typeahead="contact as contact.label for contact in $ctrl.getContacts($viewValue)" typeahead-loading="loadingContacts" typeahead-no-results="noResults" typeahead-min-length="0" typeahead-on-select="$ctrl.getContact($model, false)" typeahead-focus-on-select="$ctrl.getContacts()" typeahead-template-url="src/rechercher-contacts/templateResultatTypeahead.html" class="form-control"><div class="loading-contacts" ng-show="loadingContacts"><div class="spinner-icon"></div></div><div class="no-results" ng-show="noResults"> <i class="material-icons">warning</i> Aucun r\xE9sultat</div></div>');
$templateCache.put('src/rechercher-contacts/templateResultatTypeahead.html',' <a><span>{{match.model.nom}} {{match.model.prenom}}</span><br/> <span><small>{{match.model.email}}</small></span></a>');}]);